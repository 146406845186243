import Profile1 from "../../assets/images/yana.webp";
import Profile2 from "../../assets/images/diana.webp";
import Profile3 from "../../assets/images/talib.webp";
import Profile4 from "../../assets/images/elena.webp";
import Profile5 from "../../assets/images/baur.webp";
import Profile6 from "../../assets/images/wise.webp";
import Profile7 from "../../assets/images/shahnoza.webp";
import Profile8 from "../../assets/images/mila.webp";

export const SliderData = [
  {
    description:
      "Огромная благодарность Вугару за помощь с оформлением ВНЖ! Я не владею турецким языком, а английский в общении с чиновниками, с которыми нужно общаться, не слишком помогает. Посмотрев как мучаются месяцами некоторые соотечественники, пытаясь оформить все документы самостоятельно, я решила что лучше будет обратиться за помощью. И мне действительно повезло что порекомендовали Вугара! Благодаря его помощи я оформила все документы буквально за пару дней, Вугар сопровождал меня во всех учреждениях куда приходилось обращаться, терпеливо разъяснял все вопросы, а их у меня было немало. Вскоре мне одобрили икамет на год, а теперь готова сама карточка! Искренне рекомендую Вугара как замечательного специалиста и отзывчивого человека!",
    image: Profile6,
    alt: "avatar",
    name: "wise.wild.world",
    occ: "",
  },
  {
    description:
      "Хотел бы сказать Вугару огромное спасибо за ту помощь, которую он оказал! Все прошло быстро, все вопросы были решены. Вугар всегда поможет и доведёт дело до конца. Безумно рад, что довелось познакомиться именно с вами, Вугар, и впредь буду обращаться только к вам, и всем остальным тоже настоятельно рекомендую!! Таких профессионалов очень мало, и если вы обратитесь к Вугару, то будьте уверены, что все у вас получится!",

    image: Profile3,
    alt: "avatar",
    name: "shillaev",
    occ: "Talib Shillaev",
  },
  {
    description:
      "Всем доброго времени суток, Хочу выразить благодарность, У вас всегда есть чёткий план действий, высокий уровень профессионализма Рекомендую, Спасибо что вошли в положение и за короткий срок заселили нас 🙏.",

    image: Profile7,
    alt: "avatar",
    name: "shahnoza_muradova",
    occ: "Shahnoza Muradova",
  },
  {
    description:
      "Получили ВНЖ на год благодаря Вугару! Хотя сейчас это очень и очень трудно в Стамбуле. Большой профессионал своего дела! Всем рекомендую! Спасибо вам большое Вугар! 🙌🏼🙏🏼👍🏼",
    image: Profile8,
    alt: "avatar",
    name: "mila.shamraeva",
    occ: "Mila Shamraeva",
  },
  {
    description:
      "В августе празднуем 6 летние нашего знакомства..нашей дружбы и взаимопомощи!Человек слова...Надёжное плечо..Мудрый советчик!Трудно в одном комментарии описать его помощь в моей Турецкой жизни!Рекомендовала...рекомендую и буду рекомендовать!Успехов и процветания!Мирного неба!",
    image: Profile4,
    alt: "avatar",
    name: "elena_arinenko_sunbul",
    occ: "Elena Arinenko",
  },
  {
    description:
      "Вугар…..!!! спасибо Вам за профессиональную Работу! Очень рада что обратилась именно к Вам, и Вы смогли дистанционно, без моего присутствия, решить нашу проблему! Даже находясь за тысячу км от Вас, я точно знала, что Вы человек надежный. Спасибо большое за отзывчивость и за хорошее общение ….Ещё раз огромное спасибо! Карьерного роста вам и больших вознаграждений👍👍👍👍",
    image: Profile2,
    alt: "avatar",
    name: "chebanova882021",
    occ: "Diana Chebanova",
  },
  {
    description:
      "Сегодня получила положительный ответ на получение ВНЖ, с новыми правилами было немного страшно, но благодаря Вам все получилось 😊 все как всегда быстро, строго по делу и профессионально ! Спасибо Вам 🙏🏻",
    image: Profile1,
    alt: "avatar",
    name: "yanakafary",
    occ: "Yana Kafary",
  },
  {
    description:
      "Сегодня получил уведомление от иммиграционной службы об одобрении моей заявки на получение ВНЖ (по тапу). До этого у меня была рабочая виза (т.н. чалышма) и ее надо было поменять на «отурма» (внж). В общем, обратился к Вугару за помощью. Все было четко проинструктировано и подготовлено. Надо было только заверить нотариальный перевод свид о браке в консульстве и проставить печать в муниципалитете, а также там же получить нумараташ. В рекомендованном центральном отделении миграционной службы по Стамбулу в назначенный день и час пришел с пакетом доков и сдал. На 4-й рабочий день пришла смска об одобрении. Теперь осталось дождаться получения карты по почте. Иншаллах, и ее получим. Огромное спасибо за помощь Вугару. До этого делали ВНЖ жене - тоже через него, все тоже было четко. Главное все делать по инструкции и иметь на готове все копии и оригиналы",
    image: Profile5,
    alt: "avatar",
    name: "baur.issa",
    occ: "Baur Issa",
  },
];
